import { GlobalHeader } from '@/components/partGroups/navigations/GlobalHeader'
import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import styled from '@emotion/styled'
import type { GetServerSidePropsContext, NextPage } from 'next'
import { GlobalFooter } from '@/components/partGroups/navigations/GlobalFooter'
import { ButtonDefault } from '@/components/parts/buttons/ButtonDefault'
import { ButtonArrow } from '@/components/parts/buttons/ButtonArrow'
import nookies from 'nookies'
import Image from 'next/image'

import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectFade, Navigation, Pagination, Autoplay } from 'swiper'

import { getContentList } from '@/features/content/api/getContentList'
import type { PublicNews } from '@/features/content/types'
import { ContentNews } from '@/components/partGroups/contents/ContentNews'

import { media } from '@/components/media'

/* eslint-disable import/no-unresolved */
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

import { useToggleHeaderColor } from '@/hooks/useToggleHeaderColor'
import { CommonMeta } from '@/components/CommonMeta'
import { appUrls } from '@/utils/appUrls'
import { Toaster } from '@/components/parts/others/Toaster'
/* eslint-enable import/no-unresolved */

interface Props {
  publicNewsList: PublicNews[]
  token: string
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const { hzSession = '' } = nookies.get(context)

  const { contents } = await getContentList<PublicNews>({
    endPoint: 'public-news',
    limit: 3
  })
  return {
    props: { publicNewsList: contents, token: hzSession }
  }
}

const Top: NextPage<Props> = ({ publicNewsList, token }) => {
  const { headerColor } = useToggleHeaderColor(619)
  const [showSwiper, setShowSwiper] = useState(false)
  useEffect(() => {
    setShowSwiper(true)
  }, [])

  return (
    <>
      <CommonMeta
        title='中森明菜 OFFICIAL WEBSITE'
        type='website'
        description='歌手、女優の中森明菜の公式ウェブサイトです。デビュー40周年としてファンのみなさまにメッセージを届けたく、Twitterと本ウェブサイトを開設しました。本ウェブサイト上で新たなファンクラブとして、中森明菜 OFFICIAL FANCLUB：ALDEAもオープンしています。'
        keyword=''
      />
      <DivWrapPage>
        <div className='wrapContent'>
          <GlobalHeader color={headerColor} token={token} />
          <MainContentWrap>
            {/* heroエリア */}
            <section className='heroSection'>
              <div>
                <Image src='/img/slider/202405.jpg' alt='' layout='fill' objectFit='cover' />
              </div>
              {/* {showSwiper && (
                <Swiper
                  modules={[EffectFade, Navigation, Pagination, Autoplay]}
                  effect={'fade'}
                  pagination={{
                    clickable: true
                  }}
                  loop={true}
                  autoplay={{
                    delay: 4000
                  }}
                  speed={2000}
                >
                  <SwiperSlide>
                    <div className='sliderItem'>
                      <picture>
                        <source srcSet='/img/slider/1-1.webp' type='image/webp' />
                        <img src='/img/slider/1-1.jpg' alt='' />
                      </picture>
                      <picture className='hidden md:block'>
                        <source
                          media='(min-width:768px)'
                          srcSet='/img/slider/1-2.webp'
                          type='image/webp'
                        />
                        <source media='(min-width:768px)' srcSet='/img/slider/1-2.jpg' />
                        <img src='' alt='' />
                      </picture>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className='sliderItem'>
                      <picture>
                        <source srcSet='/img/slider/2-1.webp' type='image/webp' />
                        <img src='/img/slider/2-1.jpg' alt='' />
                      </picture>
                      <picture className='hidden md:block'>
                        <source
                          media='(min-width:768px)'
                          srcSet='/img/slider/2-2.webp'
                          type='image/webp'
                        />
                        <source media='(min-width:768px)' srcSet='/img/slider/2-2.jpg' />
                        <img src='' alt='' />
                      </picture>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className='sliderItem'>
                      <picture>
                        <source srcSet='/img/slider/3-1.webp' type='image/webp' />
                        <img src='/img/slider/3-1.jpg' alt='' />
                      </picture>
                      <picture className='hidden md:block'>
                        <source
                          media='(min-width:768px)'
                          srcSet='/img/slider/3-2.webp'
                          type='image/webp'
                        />
                        <source media='(min-width:768px)' srcSet='/img/slider/3-2.jpg' />
                        <img src='' alt='' />
                      </picture>
                    </div>
                  </SwiperSlide>
                </Swiper>
              )} */}
            </section>
            {/* メッセージ */}
            <section className='massageSection'>
              <div className='contentWrap maxWidth'>
                <h2 className='columnOne font-20px font-mincho'>MESSAGE</h2>
                <div className='columnTwo'>
                  {/* <p className='font-16px font-mincho'>
                    2022年はデビューをさせていただいてから40年になります。40年の節目に、何がみんなの幸せにつながるのだろう？何がみんなに受け入れられるのだろう？何がみんなにとっての正義なんだろう？と、今のわたしに何ができるのかを日々考えてきました。すぐに正解を求めようとするわたし。自分で答えを出すことに覚悟が必要でしたが、私はこの道を選びました。だからこそ、私もみんなの選んだ道を尊重したいと思っています。すべてはあなた次第。すべては「自分」から始まる、と今はそう信じています。
                  </p> */}
                  <p className='font-16px font-mincho'>
                    40周年41周年と共に過ごすことを選んでくださったみなさまにとても支えられています。
                    <br />
                    みなさまの存在のおかげで、私は少し強くなれた気がします。
                    <br />
                    <br />
                    あなたは誰かを強くしている。
                    <br />
                    <br />
                    私もきっと誰かを強くしている。
                    <br />
                    <br />
                    私にできることは何もないと弱気になる自分を超えて、もしかしたら私の存在があなたの自信につながっているかもしれない、とあなたのおかげで思うことができるようになりました。
                    <br />
                    <br />
                    You make me stronger.
                    <br />
                  </p>
                  <p className='nameText font-mincho'>中森明菜</p>
                </div>
              </div>
            </section>
            {/* News */}
            <section className='maxWidth mt-30'>
              <div className='flex justify-between'>
                <h2 className='font-20px font-mincho'>NEWS</h2>
                <Link href={appUrls.news.index()}>
                  <a>
                    <ButtonArrow label='View all' />
                  </a>
                </Link>
              </div>
              <div className='newsList'>
                {publicNewsList &&
                  publicNewsList.map((publicNews) => (
                    <ContentNews
                      news={publicNews}
                      path='news'
                      key={publicNews.id}
                      className='itemNews'
                    />
                  ))}
              </div>
            </section>
            {/* ファンクラブ */}
            <section className='clubSection mt-30'>
              <div className='maxWidth flex justify-between'>
                <h2 className={`flex-1 font-20px font-mincho ${token ? '__loggedIn' : ''}`}>
                  <span className='loginHidden'>FANCLUB: </span>
                  <span className='loginVisible'>ABOUT: </span>
                  ALDEA
                </h2>
                <Link href={appUrls.aboutAldea()}>
                  <a>
                    <ButtonArrow label='入会方法' />
                  </a>
                </Link>
              </div>
              <div className='bg-beige mt-5'>
                <div className='contentWrap __nomargin items-end maxWidth pt-8 pb-8'>
                  <div className='columnTwo flex'>
                    <div className='maxWidthSm'>
                      <img src='/img/illust-aldea1.png' alt='' />
                    </div>
                    <div className='maxWidthSm md:block hidden'>
                      <img src='/img/illust-aldea1.png' alt='' />
                    </div>
                  </div>
                  <div className='columnOne pt-8'>
                    <Link href={appUrls.aldea.login()}>
                      <a>
                        <ButtonDefault label='ログイン' subLabel='FANCLUB: ALDEA' type='button' />
                      </a>
                    </Link>
                    <Link href={appUrls.aboutAldea()}>
                      <a>
                        <ButtonDefault
                          label='新規入会'
                          subLabel='FANCLUB: ALDEA'
                          color='black'
                          className='mt-2'
                          type='button'
                        />
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </section>
            <section className='profileSection'>
              <div className='maxWidth'>
                <div className='flex justify-between'>
                  <h2 className='font-20px font-mincho'>PROFILE</h2>
                  <Link href={appUrls.profile()}>
                    <a>
                      <ButtonArrow label='Read more' />
                    </a>
                  </Link>
                </div>
                <div className='mt-3'>
                  <Link href={appUrls.profile()}>
                    <a className='profileImage'>
                      <div className='hoverImageZoom'>
                        <picture>
                          <source srcSet='/img/img-profile.webp' type='image/webp' />
                          <img src='/img/img-profile.jpg' alt='' />
                        </picture>
                      </div>
                    </a>
                  </Link>
                  <p className='nameText font-mincho'>
                    中森明菜
                    <br />
                    AKINA NAKAMORI
                  </p>
                </div>
              </div>
            </section>
          </MainContentWrap>
          <GlobalFooter token={token} />
        </div>
      </DivWrapPage>

      {/* TODO:組み込み後削除 */}
      {/* <Toaster
        message='あのイーハトーヴォのすきとおった風、夏でも底に冷たさをもつ青いそら、うつくしい森で飾られたモリーオ。'
        color=''
      /> */}
    </>
  )
}

export default Top

const DivWrapPage = styled.div`
  width: 100%;
  background-color: var(--color-black);
  .wrapContent {
    width: 100%;
    margin: 0 auto;
    background: #fff;
  }
`
const MainContentWrap = styled.main`
  position: relative;
  width: 100%;
  .heroSection {
    position: relative;
    width: 100%;
    height: calc(100vh + 16px);
    background: var(--color-beige);
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .swiper {
    height: 100%;
    padding-bottom: 16px;
  }
  .sliderItem {
    display: flex;
    width: 100%;
    height: 100%;
    > * {
      width: 100%;
    }
  }
  .sliderItemSp {
    display: none;
    @media ${media.bpLg} {
      display: block;
    }
  }
  /* インジケータ */
  .swiper-pagination {
    bottom: 0;
    display: flex;
    gap: 0 10px;
    justify-content: flex-end;
    padding: 0 20px;
  }
  .swiper-slide {
    position: relative;
  }
  .swiper-pagination-bullet {
    width: 40px;
    height: 1px;
    margin: 0 !important;
    background: var(--color-white);
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 1px;
      background: var(--color-black);
      /* delay */
      animation: 4000ms linear 0s 1 normal forwards currentBullet;
    }
  }
  @keyframes currentBullet {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  /* メッセージ */
  .massageSection {
    padding: 120px 0 64px;
    background: var(--color-beige);
    @media ${media.bpXl} {
      padding: 64px 0 40px;
    }
    .nameText {
      margin-top: 40px;
      font-size: 1.0625rem;
      letter-spacing: 0.1em;
      line-height: 1.882;
      text-align: right;
    }
  }
  /* NEWS */
  .newsList {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 16px;
  }
  .itemNews {
    width: calc((100% - 60px) / 3 * 2 + 30px);
    &:last-child {
      border-bottom: 1px var(--color-black) solid;
    }
    @media ${media.bpLg} {
      width: 100%;
    }
  }
  .clubSection {
    .loginVisible {
      display: none;
    }
    .__loggedIn {
      .loginHidden {
        display: none;
      }
      .loginVisible {
        display: inline-block;
      }
    }
  }
  /* プロフィール */
  .profileSection {
    padding-top: 120px;
    padding-bottom: 20px;
    .profileImage {
      display: block;
      max-width: var(--bp-sm);
      margin: 0 auto;
    }
    .nameText {
      margin-top: 32px;
      font-size: 20px;
      letter-spacing: 0.1em;
      line-height: 1.6;
      text-align: center;
    }
  }
`
